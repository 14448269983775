import React, { useContext, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  Typography,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { formatISO } from "date-fns";
import { EmployeeContext, SnackbarContext } from "../../App";
import Firebase from "../../lib/firebase";

const useStyles = makeStyles({
  cardActions: {
    padding: "16px",
    paddingTop: "0px",
  },
});

const withdrawalSkeleton = {
  employee: "",
  type: "withdrawal",
  amount: "",
  date: "",
  timestamp: "",
};

const EmployeeWithdrawal = () => {
  const classes = useStyles();
  const date = formatISO(new Date(), { representation: "date" });

  const { setSnackbar } = useContext(SnackbarContext);
  const { employeeList } = useContext(EmployeeContext);

  const [withdrawalDetails, setWithdrawalDetails] = useState({
    ...withdrawalSkeleton,
    date,
  });

  const addWithdrawal = async (e) => {
    e.preventDefault();

    let details = { ...withdrawalDetails, timestamp: Date.now() };

    try {
      await Firebase.createDoc("transactions", details);

      setWithdrawalDetails({ ...withdrawalSkeleton, date });

      setSnackbar({
        message: "Withdrawal details submitted successfully!",
        open: true,
        color: "success",
      });
    } catch (error) {
      console.error(error);
      setSnackbar({
        message:
          "An error occured while submitting the details, please try again later.",
        open: true,
        color: "error",
      });
    }
  };

  return (
    <Card elevation={2}>
      <form onSubmit={addWithdrawal}>
        <CardContent>
          <Typography variant="h4" color="primary">
            Withdrawal
          </Typography>
          <TextField
            label="Employee"
            select
            margin="normal"
            onChange={(e) => {
              setWithdrawalDetails({
                ...withdrawalDetails,
                employee: e.target.value,
              });
            }}
            value={withdrawalDetails.employee}
            required
            variant="outlined"
            fullWidth
          >
            {employeeList.map((employee, index) => (
              <MenuItem key={index} value={employee.id}>
                {employee.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Amount"
            margin="normal"
            type="number"
            value={withdrawalDetails.amount}
            onChange={(e) =>
              setWithdrawalDetails({
                ...withdrawalDetails,
                amount: e.target.value,
              })
            }
            required
            variant="outlined"
            fullWidth
          />
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
            >
              Withdraw
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  );
};

export default EmployeeWithdrawal;
