import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { format, formatISO } from "date-fns";
import Loader from "../Loader";
import Firebase from "../../lib/firebase";
import { SnackbarContext, EmployeeContext } from "../../App";

const describeTransaction = (transaction, employees) => {
  const getEmployee = (employees, id) => {
    return employees.find((employee) => employee.id === id);
  };

  switch (transaction.type) {
    case "sale":
      return `${transaction.service.name} by ${
        getEmployee(employees, transaction.employee).name
      } for Kshs. ${Number(transaction.amount).toFixed(2)}`;

    case "withdrawal":
      return `Kshs. ${Number(transaction.amount).toFixed(2)} withdrawn by ${
        getEmployee(employees, transaction.employee).name
      }`;

    case "expense":
      return `Kshs. ${Number(transaction.amount).toFixed(2)} used for ${
        transaction.expenseType
      } - ${transaction.details}`;

    default:
      return `Kshs. ${Number(transaction.amount).toFixed(2)}`;
  }
};

const TransactionLog = () => {
  const date = formatISO(new Date(), { representation: "date" });
  const { employeeList } = useContext(EmployeeContext);

  const [transactions, setTransactions] = useState([]);
  const [transactionsFetched, setTransactionsFetched] = useState(false);
  const { snackbar } = useContext(SnackbarContext);

  useEffect(() => {
    (async () => {
      let transactions = await Firebase.getAllDocs("transactions", {
        attribute: "date",
        comparator: "==",
        value: date,
      });
      transactions.sort((a, b) => {
        return a.timestamp - b.timestamp;
      });
      setTransactions(transactions);
      setTransactionsFetched(true);
    })();
  }, [snackbar]);

  return (
    <Card elevation={2}>
      <CardContent>
        <Typography variant="h4" color="primary" gutterBottom>
          Today's Log
        </Typography>
        {!transactionsFetched && <Loader />}
        {transactionsFetched && employeeList && (
          <>
            {transactions.length > 0 ? (
              <>
                {transactions.map((transaction, index) => (
                  <ListItem key={transaction.timestamp}>
                    <ListItemIcon>
                      <Button variant="outlined" color="secondary" size="small">
                        {format(new Date(transaction.timestamp), "hh:mm a")}
                      </Button>
                    </ListItemIcon>
                    <Box ml="1em">
                      <ListItemText
                        primary={describeTransaction(transaction, employeeList)}
                      />
                    </Box>
                  </ListItem>
                ))}
              </>
            ) : (
              <ListItem>
                <Box ml="1em" textAlign="center">
                  <ListItemText primary="There are no transactions today" />
                </Box>
              </ListItem>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default TransactionLog;
