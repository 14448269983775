import React, { useState } from "react";
import { Box, Paper, Tabs, Tab } from "@material-ui/core";
import DaySummary from "../DaySummary";
import EmployeeWithdrawal from "../EmployeeWithdrawal";
import TransactionLog from "../TransactionLog";
import Sale from "../Sale";
import Expense from "../Expense";

const Landing = () => {
  const [currentTab, setCurrentTab] = useState(0);
  return (
    <>
      <Box my="1em">
        <DaySummary />
      </Box>
      <Box my="2em">
        <Box mb="1.5em">
          <Paper elevation={2}>
            <Tabs
              value={currentTab}
              onChange={(e, newTab) => setCurrentTab(newTab)}
              centered
            >
              <Tab label="Sale" />
              <Tab label="Withdrawal" />
              <Tab label="Expense" />
            </Tabs>
          </Paper>
        </Box>
        {currentTab === 0 && <Sale />}
        {currentTab === 1 && <EmployeeWithdrawal />}
        {currentTab === 2 && <Expense />}
      </Box>
      <TransactionLog />
    </>
  );
};

export default Landing;
