import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  VideocamOffOutlined,
  ArrowBack,
  ArrowForward,
} from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  CarouselProvider,
  Slider,
  Slide,
  Image,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import DateFnsUtils from "@date-io/date-fns";
import Firebase from "../../lib/firebase";
import { getDateString } from "../../lib/utils";

const useStyles = makeStyles({
  slider: {
    paddingInlineStart: "0px",
  },
  amenityButton: {
    margin: "0 0.5em 0.5em 0",
  },
});

const CCTVMonitoring = () => {
  const classes = useStyles();

  const [snaps, setSnaps] = useState([]);
  const [snapsFetched, setSnapsFetched] = useState(false);
  const [date, setDate] = useState(new Date());
  const [showPreview, setShowPreview] = useState(false);
  const [selectedImg, setSelectedImg] = useState(0);

  // Fetch the dates images
  useEffect(() => {
    (async () => {
      let datesSnapsRef = Firebase.storage.ref(`cctv/${getDateString(date)}`);
      try {
        let resp = await datesSnapsRef.listAll();
        let downloadURLs = [];
        resp.items.forEach(async (snapRef) => {
          let snapUrl = snapRef.getDownloadURL();
          downloadURLs.push(snapUrl);
        });
        setSnaps(await Promise.all(downloadURLs));
        setSnapsFetched(true);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [date]);

  return (
    <>
      <Typography variant="h4">CCTV Snapshots</Typography>
      <Box my="1em">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Choose a Date"
            value={date}
            onChange={(d) => {
              setDate(d);
              setSnapsFetched(false);
              setSnaps([]);
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            fullWidth
          />
        </MuiPickersUtilsProvider>
      </Box>
      <Divider />
      <Box my="1.5em">
        <Dialog
          open={showPreview}
          onClose={(e) => setShowPreview(false)}
          maxWidth="xl"
          fullWidth={true}
        >
          <CarouselProvider
            visibleSlides={1}
            naturalSlideWidth={1600}
            naturalSlideHeight={900}
            totalSlides={snaps.length}
            currentSlide={selectedImg}
            lockOnWindowScroll
            style={{ position: "relative" }}
          >
            <Slider classNameTray={classes.slider}>
              {snaps.map((snapUrl, index) => (
                <Slide tag="a" index={index} key={index}>
                  <Image src={snapUrl} style={{ borderRadius: "10px" }} />
                </Slide>
              ))}
            </Slider>
            <IconButton
              aria-label="previous"
              style={{ position: "absolute", top: "44%", left: "5px" }}
              size="medium"
            >
              <ButtonBack style={{ border: "none", backgroundColor: "unset" }}>
                <ArrowBack />
              </ButtonBack>
            </IconButton>
            <IconButton
              aria-label="forward"
              style={{ position: "absolute", top: "44%", right: "5px" }}
              size="medium"
            >
              <ButtonNext style={{ border: "none", backgroundColor: "unset" }}>
                <ArrowForward />
              </ButtonNext>
            </IconButton>
          </CarouselProvider>
        </Dialog>
        <Grid container spacing={2}>
          {snapsFetched && (
            <>
              {snaps.length > 0 ? (
                <>
                  {snaps.map((imageUrl, idx) => (
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={3}
                      style={{ position: "relative" }}
                      key={imageUrl}
                    >
                      <img
                        src={imageUrl}
                        alt=""
                        style={{
                          borderRadius: "15px",
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        onClick={(e) => {
                          setSelectedImg(idx);
                          setShowPreview(true);
                        }}
                        decoding="async"
                      />
                    </Grid>
                  ))}
                </>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                >
                  <VideocamOffOutlined />
                  <Typography align="center">
                    There are no CCTV snaps taken on this day
                  </Typography>
                </Box>
              )}
            </>
          )}
          {!snapsFetched && (
            <>
              {Array.from([1, 2, 3, 4, 5, 6, 7, 8]).map((el) => (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  style={{ position: "relative", aspectRatio: "229.2/192.35" }}
                  key={el}
                >
                  <Skeleton
                    variant="rect"
                    style={{ borderRadius: "15px" }}
                    height="100%"
                    width="100%"
                    animation="pulse"
                  />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default CCTVMonitoring;
