import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { format, addDays } from "date-fns";
import { EmployeeContext, SnackbarContext } from "../../App";
import Firebase from "../../lib/firebase";
import { getDateString } from "../../lib/utils";

const useStyles = makeStyles((theme) => ({
  today: {
    display: "flex",
    alignItems: "center",
  },
  amountToday: {
    fontSize: "3em",
    fontWeight: 700,
  },
  pctToday: {
    fontSize: "2.25em",
    fontWeight: "500",
    lineHeight: "1.1em",
  },
  perfToday: {},
  summSection: {
    padding: "0 1em",
    borderRight: "solid 2px #C4C4C4",
    "&:last-child": {
      borderRight: "none",
    },
  },
}));

const DaySummary = () => {
  const today = new Date();
  const yesterday = addDays(today, -1);

  const classes = useStyles();

  const { employeeList } = useContext(EmployeeContext);
  const { snackbar } = useContext(SnackbarContext);

  const [totalSalesToday, setTotalSalesToday] = useState(0);
  const [totalSalesYesterday, setTotalSalesYesterday] = useState(0);
  const [todaysCommissions, setTodaysCommissions] = useState({});

  // Set today's totals
  useEffect(() => {
    (async () => {
      try {
        let todaysInflows = await Firebase.getDoc(
          "daily-inflows",
          getDateString(today)
        );
        setTotalSalesToday(todaysInflows.total);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [snackbar]);

  // Set yesterday's totals
  useEffect(() => {
    (async () => {
      try {
        let yesterdaysInflows = await Firebase.getDoc(
          "daily-inflows",
          getDateString(yesterday)
        );
        setTotalSalesYesterday(yesterdaysInflows.total);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  // Set todays's commissions
  useEffect(() => {
    (async () => {
      try {
        let todaysCommissions = await Firebase.getDoc(
          "daily-commissions",
          getDateString(today)
        );
        setTodaysCommissions(todaysCommissions);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [snackbar]);

  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        <Card elevation={2}>
          <CardContent>
            <Box className={classes.today}>
              <Typography
                variant="h6"
                component="h2"
                style={{ marginRight: "0.5em", textTransform: "uppercase" }}
                color="primary"
              >
                Today
              </Typography>
              <Typography component="h3">
                {format(today, "cccc do MMM")}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" className={classes.amountToday}>
                Ksh. {totalSalesToday.toFixed(2)}
              </Typography>
              <Grid container alignItems="center">
                <Typography
                  variant="h6"
                  align="center"
                  className={classes.pctToday}
                >
                  {
                    -(
                      ((totalSalesYesterday - totalSalesToday) /
                        (totalSalesYesterday || 1)) *
                      100
                    ).toFixed(1)
                  }
                  % from yesterday
                </Typography>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6}>
        <Card elevation={2}>
          <CardContent>
            <Box>
              <Typography
                variant="h6"
                component="h2"
                style={{ textTransform: "uppercase" }}
                color="primary"
              >
                Commissions
              </Typography>
            </Box>
            <Box>
              <List dense={true}>
                {employeeList.map((employee) => (
                  <ListItem key={employee.id} button>
                    <ListItemText
                      primary={`${employee.name} - Ksh. ${
                        todaysCommissions[employee.id]
                      }`}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DaySummary;
