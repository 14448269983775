import React from "react";
import { CircularProgress, Box } from "@material-ui/core";

const Loader = () => (
  <Box display="flex" justifyContent="center">
    <CircularProgress color="secondary" />
  </Box>
);

export default Loader;
