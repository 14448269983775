import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Switch,
  Typography,
} from "@material-ui/core";
import { CreateOutlined, DeleteOutline } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Firebase from "../../lib/firebase";

const useStyles = makeStyles({
  cardActions: {
    padding: "8px 16px",
  },
});

const serviceSkeleton = {
  name: "",
  standardValue: 0,
  commissionable: false,
};

const ClientServices = () => {
  const classes = useStyles();

  const [newServiceDialogOpen, setNewServiceDialogOpen] = useState(false);
  const [serviceDetails, setServiceDetails] = useState(serviceSkeleton);
  const [action, setAction] = useState("create");
  const [services, setServices] = useState([]);

  // Fetch services
  useEffect(() => {
    (async () => {
      try {
        let services = await Firebase.getAllDocs("services");
        setServices(services);
      } catch (error) {
        console.error(error);
      }
    })();
  });

  const addService = async () => {
    try {
      await Firebase.createDoc("services", serviceDetails);

      setServiceDetails(serviceSkeleton);
      setNewServiceDialogOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const updateService = async () => {
    try {
      await Firebase.updateDoc("services", serviceDetails.id, serviceDetails);

      setServiceDetails(serviceSkeleton);
      setNewServiceDialogOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteService = async (serviceId) => {
    try {
      await Firebase.deleteDoc("services", serviceId);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Typography variant="h4">Client Services</Typography>
      <Box my="1em">
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              setAction("create");
              setServiceDetails(serviceSkeleton);
              setNewServiceDialogOpen(true);
            }}
            disableElevation
          >
            Add Service
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box my="1.5em">
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow style={{ fontFamily: "Lato", fontWeight: "bolder" }}>
                  <TableCell>Name</TableCell>
                  <Hidden xsDown>
                    <TableCell>Cost</TableCell>
                    <TableCell>Commissionable</TableCell>
                  </Hidden>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {services.length < 1 && (
                  <TableRow>
                    <TableCell align="center" colSpan={4}>
                      You haven't added any services yet
                    </TableCell>
                  </TableRow>
                )}
                {services.length > 0 &&
                  services.map((service) => (
                    <TableRow>
                      <TableCell>{service.name}</TableCell>
                      <Hidden xsDown>
                        <TableCell>
                          Kshs. {Number(service.standardValue).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          <Switch checked={service.commissionable} />
                        </TableCell>
                      </Hidden>
                      <TableCell align="center">
                        <IconButton
                          onClick={(e) => {
                            setAction("edit");
                            setServiceDetails(service);
                            setNewServiceDialogOpen(true);
                          }}
                          style={{ marginRight: "0.5em" }}
                        >
                          <CreateOutlined />
                        </IconButton>
                        <IconButton onClick={(e) => deleteService(service.id)}>
                          <DeleteOutline />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <Dialog
        open={newServiceDialogOpen}
        onClose={(e) => setNewServiceDialogOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {action === "create" && "New Service"}
          {action === "edit" && "Change Service Details"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                name="name"
                variant="outlined"
                label="Name"
                margin="normal"
                value={serviceDetails.name}
                onChange={(e) =>
                  setServiceDetails({
                    ...serviceDetails,
                    name: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="standardValue"
                variant="outlined"
                label="Cost"
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ marginRight: "1em" }}>
                      Kshs.
                    </InputAdornment>
                  ),
                }}
                type="number"
                value={serviceDetails.standardValue}
                onChange={(e) =>
                  setServiceDetails({
                    ...serviceDetails,
                    standardValue: e.target.value,
                  })
                }
                margin="normal"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6} container alignItems="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={serviceDetails.commissionable}
                    value={serviceDetails.commissionable}
                    onChange={(e) =>
                      setServiceDetails({
                        ...serviceDetails,
                        commissionable: !serviceDetails.commissionable,
                      })
                    }
                  />
                }
                label="Commissionable?"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Box display="flex" justifyContent="flex-end">
            <Box mr="1em">
              <Button
                onClick={(e) => setNewServiceDialogOpen(false)}
                size="large"
              >
                Cancel
              </Button>
            </Box>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={(e) => {
                if (action === "create") {
                  addService();
                } else {
                  updateService();
                }
              }}
              disableElevation
            >
              {action === "create" && "Add Service"}
              {action === "edit" && "Update Service"}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClientServices;
