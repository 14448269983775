import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { nanoid } from "nanoid";

const firebaseConfig = {
  apiKey: "AIzaSyC3ZQV_SEZw_voio6DvBwvMai-8w7h0F1A",
  authDomain: "jeffs-1534600536032.firebaseapp.com",
  databaseURL: "https://jeffs-1534600536032.firebaseio.com",
  projectId: "jeffs-1534600536032",
  storageBucket: "jeffs-1534600536032.appspot.com",
  messagingSenderId: "1070100234023",
  appId: "1:1070100234023:web:998b50a287552145",
};

class Firebase {
  constructor() {
    firebase.initializeApp(firebaseConfig);
    this.auth = firebase.auth();
    this.firestore = firebase.firestore();
    this.storage = firebase.storage();
  }

  login(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  logout() {
    return this.auth.signOut();
  }

  getCurrentUser() {
    return this.auth.currentUser;
  }

  timestampFromDate(date) {
    return firebase.firestore.Timestamp.fromDate(date);
  }

  isInitialized() {
    return new Promise((resolve) => {
      this.auth.onAuthStateChanged(resolve);
    });
  }

  createDoc(collection, data) {
    return this.firestore.collection(collection).doc(nanoid(9)).set(data);
  }

  async getAllDocs(
    collection,
    condition = { attribute: null, comparator: null, value: null }
  ) {
    let docs = [];
    let snapshot;
    condition.attribute
      ? (snapshot = await this.firestore
          .collection(collection)
          .where(condition.attribute, condition.comparator, condition.value)
          .get())
      : (snapshot = await this.firestore.collection(collection).get());
    snapshot.forEach((doc) => {
      let docData = doc.data();
      docData.id = doc.id;
      docs.push(docData);
    });
    return docs;
  }

  async getDoc(collection, docId) {
    let doc = await this.firestore.collection(collection).doc(docId).get();
    if (doc.exists) return doc.data();
    throw new Error("The document you requested doesn't exist");
  }

  updateDoc(collection, docId, data) {
    return this.firestore.collection(collection).doc(docId).update(data);
  }

  deleteDoc(collection, docId) {
    return this.firestore.collection(collection).doc(docId).delete();
  }
}

export default new Firebase();
