import React, { useContext, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  DeleteOutline,
  EditOutlined,
  VisibilityOutlined,
} from "@material-ui/icons";
import { EmployeeContext } from "../../App";
import Firebase from "../../lib/firebase";

const useStyles = makeStyles({
  employee: {
    margin: "1em 0",
  },
  avatar: {
    height: "3em",
    width: "3em",
  },
  actions: {
    padding: "8px 24px",
  },
});

const employeeSkeleton = {
  name: "",
  phone: "",
  onCommission: true,
  salary: 0,
  idNumber: "",
  emergencyContact: {
    name: "",
    phone: "",
  },
};

const Employees = () => {
  const classes = useStyles();

  const { employeeList, fetchEmployees } = useContext(EmployeeContext);

  const [newEmployeeDialogOpen, setNewEmployeeDialogOpen] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState(employeeSkeleton);

  const addEmployee = async () => {
    try {
      await Firebase.createDoc("employees", employeeDetails);

      setEmployeeDetails(employeeSkeleton);
      setNewEmployeeDialogOpen(false);
      await fetchEmployees();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Typography variant="h4">Employees</Typography>
      <Box my="1em">
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => setNewEmployeeDialogOpen(true)}
            disableElevation
          >
            Add Employee
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box my="1.5em">
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow style={{ fontFamily: "Lato", fontWeight: "bolder" }}>
                  <TableCell>Employee</TableCell>
                  <Hidden xsDown>
                    <TableCell align="center">Amount Due</TableCell>
                  </Hidden>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {employeeList.length < 1 && (
                  <TableRow>
                    <TableCell align="center" colSpan={4}>
                      You don't have any employees yet
                    </TableCell>
                  </TableRow>
                )}
                {employeeList.length > 0 &&
                  employeeList.map((employee, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Avatar style={{ marginRight: "1em" }}>
                            {employee.name[0]}
                          </Avatar>
                          <Typography>{employee.name}</Typography>
                        </Box>
                      </TableCell>
                      <Hidden xsDown>
                        <TableCell align="center">
                          Kshs. {Number(employee.payable).toFixed(2)}
                        </TableCell>
                      </Hidden>
                      <TableCell align="center">
                        <IconButton>
                          <VisibilityOutlined />
                        </IconButton>
                        <IconButton>
                          <EditOutlined />
                        </IconButton>
                        <IconButton>
                          <DeleteOutline />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <Dialog
        open={newEmployeeDialogOpen}
        onClose={(e) => setNewEmployeeDialogOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>New Employee</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="name"
                variant="outlined"
                label="Name"
                margin="normal"
                value={employeeDetails.name}
                onChange={(e) =>
                  setEmployeeDetails({
                    ...employeeDetails,
                    name: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="phone"
                variant="outlined"
                label="Phone"
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ marginRight: "1em" }}>
                      +254
                    </InputAdornment>
                  ),
                }}
                value={employeeDetails.phone}
                onChange={(e) =>
                  setEmployeeDetails({
                    ...employeeDetails,
                    phone: e.target.value,
                  })
                }
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonGroup color="primary" size="large" fullWidth>
                <Button
                  variant={
                    employeeDetails.onCommission ? "outlined" : "contained"
                  }
                  onClick={(e) =>
                    setEmployeeDetails({
                      ...employeeDetails,
                      onCommission: false,
                    })
                  }
                  disableElevation
                >
                  Salary
                </Button>
                <Button
                  variant={
                    employeeDetails.onCommission ? "contained" : "outlined"
                  }
                  onClick={(e) =>
                    setEmployeeDetails({
                      ...employeeDetails,
                      onCommission: true,
                    })
                  }
                  disableElevation
                >
                  Commission
                </Button>
              </ButtonGroup>
            </Grid>
            {!employeeDetails.onCommission && (
              <Grid item xs={12}>
                <TextField
                  name="salary"
                  variant="outlined"
                  label="Salary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment style={{ marginRight: "1em" }}>
                        Kshs.{" "}
                      </InputAdornment>
                    ),
                  }}
                  value={employeeDetails.salary}
                  onChange={(e) =>
                    setEmployeeDetails({
                      ...employeeDetails,
                      salary: e.target.value,
                    })
                  }
                  type="number"
                  margin="normal"
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                name="id"
                variant="outlined"
                label="ID Number"
                value={employeeDetails.idNumber}
                onChange={(e) =>
                  setEmployeeDetails({
                    ...employeeDetails,
                    idNumber: e.target.value,
                  })
                }
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Emergency Contact</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="emergencyContactName"
                    variant="outlined"
                    label="Name"
                    value={employeeDetails.emergencyContact.name}
                    onChange={(e) =>
                      setEmployeeDetails({
                        ...employeeDetails,
                        emergencyContact: {
                          ...employeeDetails.emergencyContact,
                          name: e.target.value,
                        },
                      })
                    }
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="emergencyContactPhone"
                    variant="outlined"
                    label="Phone"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment style={{ marginRight: "1em" }}>
                          +254
                        </InputAdornment>
                      ),
                    }}
                    value={employeeDetails.emergencyContact.phone}
                    onChange={(e) =>
                      setEmployeeDetails({
                        ...employeeDetails,
                        emergencyContact: {
                          ...employeeDetails.emergencyContact,
                          phone: e.target.value,
                        },
                      })
                    }
                    margin="normal"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Box display="flex" justifyContent="flex-end">
            <Box mr="1em">
              <Button
                onClick={(e) => setNewEmployeeDialogOpen(false)}
                size="large"
              >
                Cancel
              </Button>
            </Box>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={(e) => addEmployee()}
              disableElevation
            >
              Add Employee
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Employees;
