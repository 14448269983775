import React from "react";
import { Route, useHistory } from "react-router-dom";
import { Box, Container, Grid, Hidden, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "../Navbar";
import NavItems from "../NavItems";
import Firebase from "../../lib/firebase";
import Landing from "../Landing";
import Employees from "../Employees";
import ClientServices from "../ClientServices";
import CCTVMonitoring from "../CCTVMonitoring";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
  },
  sideNav: {
    background: "rgba(255,61,0,0.05)",
  },
  sideNavImg: {
    borderRadius: "100%",
    height: "5em",
  },
  sideNavContainer: {
    height: "100%",
    maxWidth: "25%",
    position: "fixed",
  },
  listItem: {
    color: "#FAFAFA",
  },
  listItemIcon: {
    color: "#FAFAFA",
  },
  main: {
    background: "#FFF",
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  const history = useHistory();

  if (!Firebase.getCurrentUser()) {
    // not logged in
    console.error("Please login first");
    history.replace("/login");
    return null;
  }

  return (
    <>
      <Grid container className={classes.root}>
        <Navbar />
        <Hidden smDown>
          <Grid item className={classes.sideNav} md={3}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              className={classes.sideNavContainer}
            >
              <Grid item md={8}>
                <Box display="flex" justifyContent="center">
                  <img
                    src="/logo.png"
                    className={classes.sideNavImg}
                    alt="logo"
                  />
                </Box>
                <Box mt="2em">
                  <List>
                    <NavItems />
                  </List>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={9} className={classes.main}>
          <Box mt="5.5em">
            <Container>
              <Route path="/" exact component={Landing} />
              <Route path="/employees" exact component={Employees} />
              <Route path="/services" exact component={ClientServices} />
              <Route path="/cctv" exact component={CCTVMonitoring} />
            </Container>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
