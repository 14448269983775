import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  TextField,
  Typography,
  CardActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { formatISO } from "date-fns";
import { EmployeeContext, SnackbarContext } from "../../App";
import Firebase from "../../lib/firebase";

const useStyles = makeStyles({
  cardActions: {
    padding: "16px",
    paddingTop: "0px",
  },
});

const transactionSkeleton = {
  employee: "",
  service: "",
  type: "sale",
  amount: "",
  date: "",
  timestamp: "",
};

const Sale = () => {
  const classes = useStyles();
  const date = formatISO(new Date(), { representation: "date" });

  const { employeeList } = useContext(EmployeeContext);
  const { setSnackbar } = useContext(SnackbarContext);

  const [serviceList, setServiceList] = useState([]);
  const [chosenService, setChosenService] = useState("");
  const [transactionDetails, setTransactionDetails] = useState({
    ...transactionSkeleton,
    date,
  });

  // Fetch available services
  useEffect(() => {
    (async () => {
      try {
        let services = await Firebase.getAllDocs("services");
        setServiceList(services);
      } catch (error) {
        console.error(error);
        setSnackbar({
          message: "An error occurred while fetching available services!",
          open: true,
          color: "error",
        });
      }
    })();
  }, []);

  const addTransaction = async (e) => {
    e.preventDefault();

    let details = { ...transactionDetails, timestamp: Date.now() };

    try {
      await Firebase.createDoc("transactions", details);

      setChosenService("");
      setTransactionDetails({ ...transactionSkeleton, date });

      setSnackbar({
        message: "Sale submitted successfully!",
        open: true,
        color: "success",
      });
    } catch (error) {
      console.error(error);
      setSnackbar({
        message:
          "An error occured while submitting the details, please try again later.",
        open: true,
        color: "error",
      });
    }
  };

  return (
    <Card elevation={2}>
      <form onSubmit={addTransaction}>
        <CardContent>
          <Typography variant="h4" color="primary">
            New Sale
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Choose a Service"
                select
                margin="normal"
                onChange={(e) => {
                  setTransactionDetails({
                    ...transactionDetails,
                    service: {
                      name: e.target.value.name,
                      id: e.target.value.id,
                    },
                    amount: e.target.value.standardValue,
                  });
                  setChosenService(e.target.value);
                }}
                value={chosenService}
                required
                variant="outlined"
                fullWidth
              >
                {serviceList.map((service, index) => (
                  <MenuItem key={index} value={service}>
                    {service.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Amount"
                margin="normal"
                type="number"
                value={transactionDetails.amount}
                onChange={(e) =>
                  setTransactionDetails({
                    ...transactionDetails,
                    amount: e.target.value,
                  })
                }
                required
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>

          <TextField
            label="Who did it?"
            select
            margin="normal"
            onChange={(e) => {
              setTransactionDetails({
                ...transactionDetails,
                employee: e.target.value,
              });
            }}
            value={transactionDetails.employee}
            required
            variant="outlined"
            fullWidth
          >
            {employeeList.map((employee, index) => (
              <MenuItem key={employee.id} value={employee.id}>
                {employee.name}
              </MenuItem>
            ))}
          </TextField>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
            >
              Add
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  );
};

export default Sale;
