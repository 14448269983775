import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { formatISO } from "date-fns";
import { SnackbarContext } from "../../App";
import Firebase from "../../lib/firebase";

const expenseTypes = ["Electricity", "Goods", "Repair & Maintenance", "Other"];
const expenseTypeHelpers = [
  "Enter the token number",
  "Enter the goods that were bought",
  "Enter what the money was used for",
  "Enter what the money was used for",
];

const useStyles = makeStyles({
  cardActions: {
    padding: "16px",
    paddingTop: "0px",
  },
});

const expenseSkeleton = {
  details: "",
  amount: "",
  type: "expense",
  expenseType: "",
  date: "",
  timestamp: "",
};

const Expense = () => {
  const classes = useStyles();

  const date = formatISO(new Date(), { representation: "date" });

  const [expenseDetails, setExpenseDetails] = useState({
    details: "",
    amount: "",
    type: "expense",
    expenseType: "",
    date,
    timestamp: "",
  });

  const { setSnackbar } = useContext(SnackbarContext);

  const addExpense = async (e) => {
    e.preventDefault();

    let details = { ...expenseDetails, timestamp: Date.now() };

    try {
      await Firebase.createDoc("transactions", details);

      setExpenseDetails({ ...expenseSkeleton, date });

      setSnackbar({
        message: "Expense details submitted successfully!",
        open: true,
        color: "success",
      });
    } catch (error) {
      console.error(error);
      setSnackbar({
        message:
          "An error occured while submitting the details, please try again later",
        open: true,
        color: "error",
      });
    }
  };

  return (
    <Card elevation={2}>
      <form onSubmit={addExpense}>
        <CardContent>
          <Typography variant="h4" color="primary">
            Expense
          </Typography>
          <TextField
            label="For"
            margin="normal"
            value={expenseDetails.expenseType}
            onChange={(e) =>
              setExpenseDetails({
                ...expenseDetails,
                expenseType: e.target.value,
              })
            }
            select
            required
            variant="outlined"
            fullWidth
          >
            {expenseTypes.map((expense, index) => (
              <MenuItem key={index} value={expense}>
                {expense}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Amount"
            margin="normal"
            type="number"
            value={expenseDetails.amount}
            onChange={(e) =>
              setExpenseDetails({
                ...expenseDetails,
                amount: e.target.value,
              })
            }
            required
            variant="outlined"
            fullWidth
          />
          <TextField
            label="Expense Details"
            margin="normal"
            value={expenseDetails.details}
            onChange={(e) =>
              setExpenseDetails({
                ...expenseDetails,
                details: e.target.value,
              })
            }
            helperText={
              expenseTypeHelpers[
                expenseTypes.findIndex(
                  (expense) => expense === expenseDetails.expenseType
                )
              ]
            }
            required
            variant="outlined"
            multiline
            rows={2}
            rowsMax={6}
            fullWidth
          />
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
            >
              Add Expense
            </Button>
          </Box>
        </CardActions>
      </form>
    </Card>
  );
};

export default Expense;
